import { isArray } from 'lodash-es';
import { useQuery } from '@tanstack/vue-query';
import type { NullableMaybeRef } from '@glow/shared/utils/types';
import type { Coords } from '@glow/shared/lib/coords';
import { dependOnValues, nonNullableMaybeRef } from '@glow/shared/lib/vueQuery';
import { apiRequest } from '@glow/shared/lib/http';

export type GeoCodeObjctKind = 'street' | 'house' | 'metro' | 'locality';

export type GeoCodeOptions = {
  kind?: GeoCodeObjctKind;
  maxResults?: number;
};

export type GeoCodeResult = {
  coords: Coords;
  fullAddress: string;
  areaName: string;
};

export type GeoCodeRequest = Coords | string;

export function geoCode(request: GeoCodeRequest, options: GeoCodeOptions = {}) {
  if (typeof request === 'string' && request.length < 3) return [];
  return apiRequest<GeoCodeResult[]>('post', '/geo/geocode', {
    geocode: isArray(request) ? request.join(',') : request,
    kind: options.kind ?? 'house',
    results: options.maxResults ?? 10,
  });
}

export function useGeoCode(
  request: NullableMaybeRef<GeoCodeRequest>,
  options: MaybeRef<GeoCodeOptions> = {}
) {
  return useQuery(
    ['geoCode', nonNullableMaybeRef(request), options],
    () => geoCode(unref(request)!, unref(options)),
    { enabled: dependOnValues([request]) }
  );
}
